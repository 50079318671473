import { BiUpArrowCircle, BiBadgeCheck, BiExit, BiSolidContact, BiCheckCircle, BiSearchAlt2 } from "react-icons/bi";
import { SidebarList } from "../@types/sidebar.types";

export const CUSTOM_SIDEBAR_ITEMS: SidebarList[] = [
	{
		redirect: "/",
		icon: BiUpArrowCircle,
		listValue: "Solution"
	},
	{
		redirect: "/",
		icon: BiBadgeCheck,
		listValue: "Why Nexcaliber?"
	},
	{
		redirect: "/eligibility-check",
		icon: BiCheckCircle,
		listValue: "Check Eligibility"
	},
	{
		redirect: "/provider-search",
		icon: BiSearchAlt2,
		listValue: "Search Provider"
	},
	{
		redirect: "/contact",
		icon: BiSolidContact,
		listValue: "Contact Us"
	},
	{
		redirect: "https://admin.enrollment.nexcaliber.com/",
		icon: BiExit,
		listValue: "Member Login"
	}
];
