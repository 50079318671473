import React from "react";
import "./claimStatusQueryForm.css";
import { Button, FormControl, FormHelperText, InputLabel, Tooltip, alpha, useTheme } from "@mui/material";
import ThemeTextField from "../../../shared/textField/ThemeTextField";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ClaimStatusQueryFormProps } from "../../../../@types/components/claimStatus.types";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ClaimStatusQueryForm: React.FC<ClaimStatusQueryFormProps> = ({
	SSNIcon,
	taxIDIcon,
	maskedSSN,
	maskedTaxID,
	handleSSNChange,
	handleDateOfServiceChange,
	handleDobChange,
	handleViewClick,
	handleTaxIDChange,
	handleClaimStatusSubmit,
	calimStatusErrorDetails,
	handleTaxIDViewClick,
	dateOfBirthPatient,
	dateOfService,
	handleResetButtonClick
}): JSX.Element => {
	const customButton = {
		cancelButtonStyle: {
			color: "#9a2ed5",
			borderColor: "#9a2ed5",
			"&:hover": {
				backgroundColor: "#f8ebff",
				borderColor: "#9a2ed5"
			}
		},
		resetButtonStyle: {
			color: "#9a2ed5",
			borderColor: "#9a2ed5",
			"&:hover": {
				backgroundColor: "#f8ebff",
				borderColor: "#9a2ed5"
			}
		},
		submitButtonStyle: {
			backgroundColor: "#9a2ed5",
			"&:hover": {
				backgroundColor: "#641b8c"
			}
		}
	};
	const theme = useTheme();
	return (
		<div className="claim-status-form-main" id="claim-status-form-main">
			<div className="grid-container">
				<div className="grid-item">
					<FormControl variant="standard" fullWidth>
						<InputLabel shrink htmlFor="bootstrap-input">
							Billing Provide Tax ID-No
						</InputLabel>
						<ThemeTextField
							endAdornment={
								maskedTaxID.length > 0 && (
									<Tooltip title={taxIDIcon ? "Hide" : "Show"} arrow>
										<div
											onClick={handleTaxIDViewClick}
											style={{
												position: "absolute",
												left: "90%",
												display: "flex",
												alignItems: "center",
												cursor: "pointer"
											}}
										>
											{taxIDIcon ? <FaEye /> : <FaEyeSlash />}
										</div>
									</Tooltip>
								)
							}
							onChange={handleTaxIDChange}
							// onChange={handleChangeInput}
							value={maskedTaxID}
							name="tax_id_no"
							fullWidth
						/>
					</FormControl>
					{calimStatusErrorDetails.taxID && <FormHelperText error>tax ID is missing</FormHelperText>}
				</div>
				<div className="grid-item">
					<FormControl variant="standard" fullWidth>
						<InputLabel shrink htmlFor="bootstrap-input">
							Member ID of the Patient
						</InputLabel>
						<ThemeTextField
							endAdornment={
								maskedSSN.length > 0 && (
									<Tooltip title={SSNIcon ? "Hide" : "Show"} arrow>
										<div
											onClick={handleViewClick}
											style={{
												position: "absolute",
												left: "90%",
												display: "flex",
												alignItems: "center",
												cursor: "pointer"
											}}
										>
											{SSNIcon ? <FaEye /> : <FaEyeSlash />}
										</div>
									</Tooltip>
								)
							}
							onInput={handleSSNChange}
							// onChange={handleChangeInput}
							value={maskedSSN}
							name="member_id"
							fullWidth
						/>
					</FormControl>
					{calimStatusErrorDetails.SSN && <FormHelperText error>SSN is missing</FormHelperText>}
				</div>
				<div className="grid-item">
					<FormControl variant="standard" fullWidth>
						<InputLabel shrink htmlFor="bootstrap-input">
							Date of Birth of the Patient
						</InputLabel>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopDatePicker
								onChange={handleDobChange}
								value={dateOfBirthPatient ? dateOfBirthPatient : null}
								sx={{
									width: "100%",
									"label + &": {
										marginTop: theme.spacing(3)
									},
									"& .MuiInputBase-input": {
										//borderRadius: 4,
										position: "relative",
										backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
										border: "1px solid",
										borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
										fontSize: 16,
										width: "100%",
										padding: "10px 12px",
										transition: theme.transitions.create([
											"border-color",
											"background-color",
											"box-shadow"
										]),
										// Use the system font instead of the default Roboto font.

										"&:focus": {
											boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
											borderColor: theme.palette.primary.main
										}
									}
								}}
							/>
						</LocalizationProvider>
						{calimStatusErrorDetails.dateOfBirthPatient && (
							<FormHelperText error>DOB is missing</FormHelperText>
						)}
					</FormControl>
				</div>
				<div className="grid-item">
					<FormControl variant="standard" fullWidth>
						<InputLabel shrink htmlFor="bootstrap-input">
							Date of Service
						</InputLabel>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopDatePicker
								onChange={handleDateOfServiceChange}
								value={dateOfService ? dateOfService : null}
								sx={{
									width: "100%",
									"label + &": {
										marginTop: theme.spacing(3)
									},
									"& .MuiInputBase-input": {
										//borderRadius: 4,
										position: "relative",
										backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
										border: "1px solid",
										borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
										fontSize: 16,
										width: "100%",
										padding: "10px 12px",
										transition: theme.transitions.create([
											"border-color",
											"background-color",
											"box-shadow"
										]),
										// Use the system font instead of the default Roboto font.

										"&:focus": {
											boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
											borderColor: theme.palette.primary.main
										}
									}
								}}
							/>
						</LocalizationProvider>
						{calimStatusErrorDetails.dateOfService && (
							<FormHelperText error>Date of Service is missing</FormHelperText>
						)}
					</FormControl>
				</div>
			</div>
			<div className="button-group-container">
				<Button
					sx={customButton.cancelButtonStyle}
					onClick={handleResetButtonClick}
					variant="outlined"
					disableElevation
				>
					Reset
				</Button>
				<Button
					sx={customButton.submitButtonStyle}
					onClick={handleClaimStatusSubmit}
					variant="contained"
					disableElevation
				>
					Submit
				</Button>
			</div>
		</div>
	);
};

export default ClaimStatusQueryForm;
