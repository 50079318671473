/* eslint-disable arrow-parens */
import React, { useCallback, useState } from "react";
import { Alert, Stack } from "@mui/material";
import ClaimStatusQueryForm from "./claimStatusQueryForm/ClaimStatusQueryForm";
import { ClaimStatusQueryFormType } from "../../../@types/components/claimStatus.types";
import ClaimStatusMemberDetails from "./claimStatusMemberDetails/ClaimStatusMemberDetails";
import ClaimStatusDetailsGrid from "./claimStatusDetailsGrid/ClaimStatusDetailsGrid";
import "./claimStatus.css";
import { IMemberDetails } from "../../../@types/memberDetails.types";
import { IClaimStatusDetails } from "../../../@types/claimStatus.types";
import { api } from "../../../utils/api";
import { monthDayFormatterForWLTQuery } from "../../../utils/commonFunctions/dates";
import { IClaimStatusErrorDetails } from "../../../@types/claimStatusError.types";

const customStyle = {
	customSuccessAlert: {
		backgroundColor: "#b3e3c1",
		fontWeight: "500"
	},
	customFailAlert: {
		backgroundColor: "#e3b3be",
		fontWeight: "500"
	}
};

const ClaimStatus: React.FC = (): JSX.Element => {
	const [alertStatus, setAlertStatus] = useState<boolean | null>(true);
	//for member id
	const [SSN, setSSN] = useState<string>("");
	const [helper, setHelper] = useState("");
	const [showHideIcon, setShowHideIcon] = useState<boolean>(false);
	const [maskedSSN, setMaskedSSN] = useState("");
	const [memberDetails, setMemberDetails] = useState<IMemberDetails | null>(null);
	const [claimStatusDetails, setClaimStatusDetails] = useState<IClaimStatusDetails[]>([]);

	const [error, setError] = useState<IClaimStatusErrorDetails>({
		SSN: false,
		dateOfBirthPatient: false,
		taxID: false,
		dateOfService: false
	});

	//for tax id
	const [taxID, setTaxID] = useState<string>("");
	const [maskedTaxID, setMaskedTaxID] = useState("");
	const [taxIDHelper, setTaxIDHelper] = useState("");
	const [taxIDShowHideIcon, setTaxIDShowHideIcon] = useState<boolean>(false);
	const [queryForm, setQueryForm] = useState<ClaimStatusQueryFormType>({
		tax_id_no: "",
		member_id: ""
	});
	const [dateOfBirthPatient, setDateOfBirthPatient] = useState<string>("");
	const [dateOfService, setDateOfService] = useState<string>("");

	const handleChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setQueryForm((prevalue: any) => {
			return {
				...prevalue, // Spread Operator
				[name]: value
			};
		});
		setError({
			...error,
			[name]: false
		});
	}, []);

	const handleDobChange = useCallback(
		(value: any) => {
			const date = new Date(value as any);
			console.log("==========date of birth==========", date);
			const _date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
			setDateOfBirthPatient(_date);
			setError({
				...error,
				dateOfBirthPatient: false
			});
		},
		[error]
	);

	const handleDateOfServiceChange = useCallback(
		(value: any) => {
			const date = new Date(value as any);
			console.log("==========date of birth==========", date);
			const _date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
			setDateOfService(_date);
			setError({
				...error,
				dateOfService: false
			});
		},
		[error]
	);

	// =========================================for member ID==================================================
	const handleSSNChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value;
		console.log(value);

		const lengthOfMasked = maskedSSN.length;
		const lengthOfInputValue = value.length;

		if (value.length > 0 && lengthOfInputValue - lengthOfMasked <= 1) {
			const last = value.length - 1;
			const ch = value.charAt(last);
			console.log("i lmask");

			//showHideIcon changes to false
			if (maskedSSN.length === 0) setShowHideIcon(false);

			if (lengthOfInputValue < lengthOfMasked) {
				if (maskedSSN.endsWith("-") || helper.endsWith("-")) {
					console.log("i dash delete");
					if (helper.endsWith("-")) {
						setHelper((previous) => previous.slice(0, -1));
					}

					if (maskedSSN.endsWith("-")) {
						setMaskedSSN((previous) => previous.slice(0, -1));
					}
					return;
				} else {
					console.log("i am deleting number");

					setMaskedSSN((previous) => previous.slice(0, -1));
					setSSN((previous) => previous.slice(0, -1));
					if (helper.includes("X")) {
						setHelper((previous) => previous.slice(0, -1));
					}

					return;
				}
			}

			// console.log(isNaN(ch)+"  --- "+ch);
			if (
				(isNaN(ch as unknown as number) && lengthOfInputValue > lengthOfMasked) ||
				isNaN(ch as unknown as number) ||
				ch.trim() == ""
			) {
				console.log("i am is nan", ch);
				setMaskedSSN(maskedSSN);
				return;
			} else {
				if (helper.includes("X")) {
					setMaskedSSN(helper);
					setHelper(SSN);
					setShowHideIcon(!showHideIcon);
				}
				if (maskedSSN.length == 11 && value.length >= 11) {
					setMaskedSSN(maskedSSN);
				} else if (maskedSSN.length < 11) {
					if (lengthOfInputValue <= 3) {
						// if(lengthOfMasked<lengthOfInputValue){

						const last = value.charAt(lengthOfInputValue - 1);
						setSSN((previous) => previous + last);
						console.log("i am lengthOfMasked< lengthOfInputValue");
						setMaskedSSN((previous) => previous + "X");
					} else if (lengthOfInputValue <= 6) {
						if (lengthOfInputValue == 4) {
							const last = value.charAt(lengthOfInputValue - 1);
							setSSN((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedSSN((previous) => previous + "-X");
						} else if (lengthOfInputValue == 5) {
							const last = value.charAt(lengthOfInputValue - 1);
							setSSN((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedSSN((previous) => previous + "X");
						} else if (lengthOfInputValue == 6) {
							const last = value.charAt(lengthOfInputValue - 1);
							setSSN((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedSSN((previous) => previous + "X");
						}
					} else {
						if (lengthOfInputValue == 7) {
							const last = value.charAt(lengthOfInputValue - 1);
							setSSN((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedSSN((previous) => previous + "-" + last);
						} else {
							const last = value.charAt(lengthOfInputValue - 1);
							setSSN((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedSSN((previous) => previous + last);
						}
					}
				} else if (value.length < 11) {
				}
			}
		} else if (value.length > 0 && lengthOfInputValue - lengthOfMasked > 1) {
			console.log("i am paste");
			// let dif=lengthOfInputValue-lengthOfMasked;

			const testIng = value.substring(lengthOfMasked);

			if (isNaN(testIng as unknown as number)) {
				console.log("ami string bujhi naa,full number hami maangi");
				return;
			}

			if (lengthOfMasked <= 3) {
				setSSN((previous) => previous + value.substring(previous.length, value.length > 9 ? 9 : value.length));

				value = value.substring(0, value.length > 9 ? 9 : value.length);

				if (value.length >= 9) {
					const lastNumber = value.substring(5);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				} else if (value.length >= 5) {
					const lastNumber = value.substring(5);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				} else if (value.length > 3) {
					if (value.length == 4) {
						setMaskedSSN("XXX-X");
					} else if (value.length == 5) {
						setMaskedSSN("XXX-XX");
					}
				} else {
					if (value.length == 1) {
						setMaskedSSN("X");
					} else if (value.length == 2) {
						setMaskedSSN("XX");
					} else {
						setMaskedSSN("XXX");
					}
				}
			} else if (lengthOfMasked <= 5) {
				setSSN((previous) => previous + value.substring(lengthOfMasked, value.length > 10 ? 10 : value.length));

				value = value.substring(0, value.length > 10 ? 10 : value.length);

				if (value.length >= 10) {
					const lastNumber = value.substring(6);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				} else if (value.length >= 6) {
					const lastNumber = value.substring(6);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				} else if (value.length > 4) {
					if (value.length == 4) {
						setMaskedSSN("XXX-");
					} else if (value.length == 5) {
						setMaskedSSN("XXX-X");
					}
				}
			} else if (lengthOfMasked <= 11) {
				setSSN((previous) => previous + value.substring(lengthOfMasked, value.length > 11 ? 11 : value.length));

				value = value.substring(0, value.length > 11 ? 11 : value.length);

				if (value.length >= 11) {
					const lastNumber = value.substring(7);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				} else if (value.length >= 7) {
					const lastNumber = value.substring(7);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				}
			} else {
				setMaskedSSN(maskedSSN);
			}
		} else {
			console.log("i am useless else");
			setMaskedSSN("");
			setSSN("");
			setHelper("");
			return;
		}
	};

	const addDashes = (inputString: string) => {
		if (inputString.length <= 3) {
			return inputString;
		}

		const firstThree = inputString.substring(0, 3);
		const remainingChars = inputString.substring(3);

		let result = firstThree;

		if (remainingChars.length >= 2) {
			result += "-" + remainingChars.substring(0, 2) + "-";
			result += remainingChars.substring(2);
		} else {
			result += "-" + remainingChars;
		}

		return result;
	};

	const handleViewClick = () => {
		setShowHideIcon(!showHideIcon);
		if (maskedSSN.length > 0 && maskedSSN.includes("X")) {
			setHelper(maskedSSN);

			const p = addDashes(SSN);

			if (maskedSSN.endsWith("-") && !p.endsWith("-")) {
				setMaskedSSN(p + "-");
			} else if (!maskedSSN.endsWith("-") && p.endsWith("-")) {
				setMaskedSSN(p.slice(0, -1));
			} else {
				setMaskedSSN(p);
			}

			// setMaskedSSN(addDashes(SSN))
		} else {
			setMaskedSSN(helper);
			setHelper(SSN);
		}
	};
	// =========================================end of member ID==================================================

	// =========================================for Tax ID==================================================
	const handleTaxIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value;
		console.log(value);

		const lengthOfMasked = maskedTaxID.length;
		const lengthOfInputValue = value.length;

		if (lengthOfInputValue < lengthOfMasked) {
			// Deleting characters
			const diffIndex = value.length;
			setMaskedTaxID((previous) => previous.slice(0, diffIndex));
			setTaxID((previous) => previous.slice(0, diffIndex));
			return;
		}
		if (lengthOfInputValue > 10) {
			return;
		}

		if (value.length > 0 && lengthOfInputValue - lengthOfMasked <= 1) {
			const last = value.length - 1;
			const ch = value.charAt(last);
			// console.log("i lmask");

			//showHideIcon changes to false
			if (maskedTaxID.length === 0) setTaxIDShowHideIcon(false);

			console.log("Checking the length of the inputValue----->", lengthOfInputValue);

			console.log("Checking the length of the maskedValue--->", lengthOfMasked);

			if (
				(isNaN(ch as unknown as number) && lengthOfInputValue > lengthOfMasked) ||
				isNaN(ch as unknown as number) ||
				ch.trim() == ""
			) {
				console.log("i am is nan", ch);
				setMaskedTaxID(maskedTaxID);
				return;
			} else {
				if (taxIDHelper.includes("X")) {
					setMaskedTaxID(taxIDHelper);
					setTaxIDHelper(taxID);
					setTaxIDShowHideIcon(!taxIDShowHideIcon);
				}
				if (maskedTaxID.length == 11 && value.length >= 11) {
					setMaskedTaxID(maskedTaxID);
				} else if (maskedTaxID.length <= 9) {
					if (lengthOfInputValue <= 2) {
						const last = value.charAt(lengthOfInputValue - 1);
						setTaxID((previous) => previous + last);
						console.log("i am lengthOfMasked < lengthOfInputValue");
						setMaskedTaxID((previous) => previous + "X");
					} else if (lengthOfInputValue <= 3) {
						// if(lengthOfMasked<lengthOfInputValue){

						const last = value.charAt(lengthOfInputValue - 1);
						setTaxID((previous) => previous + last);
						console.log("i am lengthOfMasked< lengthOfInputValue");
						setMaskedTaxID((previous) => previous + "-X");
					} else if (lengthOfInputValue <= 6) {
						if (lengthOfInputValue == 4) {
							const last = value.charAt(lengthOfInputValue - 1);
							setTaxID((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedTaxID((previous) => previous + "X");
						} else if (lengthOfInputValue == 5) {
							const last = value.charAt(lengthOfInputValue - 1);
							setTaxID((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedTaxID((previous) => previous + "X");
						} else if (lengthOfInputValue == 6) {
							const last = value.charAt(lengthOfInputValue - 1);
							setTaxID((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedTaxID((previous) => previous + "X");
						}
					} else {
						if (lengthOfInputValue == 7) {
							const last = value.charAt(lengthOfInputValue - 1);
							setTaxID((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedTaxID((previous) => previous + last);
						} else {
							const last = value.charAt(lengthOfInputValue - 1);
							setTaxID((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedTaxID((previous) => previous + last);
						}
					}
				} else if (value.length < 11) {
				}
			}
		} else if (value.length > 0 && lengthOfInputValue - lengthOfMasked > 1) {
			console.log("i am paste");
			// let dif=lengthOfInputValue-lengthOfMasked;

			const testIng = value.substring(lengthOfMasked);

			if (isNaN(testIng as unknown as number)) {
				console.log("ami string bujhi naa,full number hami maangi");
				return;
			}

			if (lengthOfMasked <= 3) {
				setTaxID(
					(previous) => previous + value.substring(previous.length, value.length > 9 ? 9 : value.length)
				);

				value = value.substring(0, value.length > 9 ? 9 : value.length);

				if (value.length >= 9) {
					const lastNumber = value.substring(5);
					setMaskedTaxID(`XX-XXX${lastNumber}`);
				} else if (value.length >= 5) {
					const lastNumber = value.substring(5);
					setMaskedTaxID(`XX-XXX${lastNumber}`);
				} else if (value.length > 3) {
					if (value.length == 4) {
						setMaskedTaxID("XXX-X");
					} else if (value.length == 5) {
						setMaskedTaxID("XXX-XX");
					}
				} else {
					if (value.length == 1) {
						setMaskedTaxID("X");
					} else if (value.length == 2) {
						setMaskedTaxID("XX");
					} else {
						setMaskedTaxID("XXX");
					}
				}
			} else if (lengthOfMasked <= 5) {
				setTaxID(
					(previous) => previous + value.substring(lengthOfMasked, value.length > 10 ? 10 : value.length)
				);

				value = value.substring(0, value.length > 10 ? 10 : value.length);

				if (value.length >= 10) {
					const lastNumber = value.substring(6);
					setMaskedTaxID(`XXX-XX-${lastNumber}`);
				} else if (value.length >= 6) {
					const lastNumber = value.substring(6);
					setMaskedTaxID(`XXX-XX-${lastNumber}`);
				} else if (value.length > 4) {
					if (value.length == 4) {
						setMaskedTaxID("XXX-");
					} else if (value.length == 5) {
						setMaskedTaxID("XXX-X");
					}
				}
			} else if (lengthOfMasked <= 11) {
				setTaxID(
					(previous) => previous + value.substring(lengthOfMasked, value.length > 11 ? 11 : value.length)
				);

				value = value.substring(0, value.length > 11 ? 11 : value.length);

				if (value.length >= 11) {
					const lastNumber = value.substring(7);
					setMaskedTaxID(`XXX-XX-${lastNumber}`);
				} else if (value.length >= 7) {
					const lastNumber = value.substring(7);
					setMaskedTaxID(`XXX-XX-${lastNumber}`);
				}
			} else {
				setMaskedTaxID(maskedTaxID);
			}
		} else {
			console.log("i am useless else");
			setMaskedTaxID("");
			setTaxID("");
			setTaxIDHelper("");
			return;
		}
	};

	const addTaxIDDashes = (inputString: string) => {
		if (inputString.length <= 2) {
			return inputString;
		}

		const firstTwo = inputString.substring(0, 2);
		const remainingChars = inputString.substring(2);

		let result = firstTwo;

		if (remainingChars.length > 0) {
			result += "-" + remainingChars;
		}

		return result;
	};

	const handleTaxIDViewClick = () => {
		setTaxIDShowHideIcon(!taxIDShowHideIcon);
		if (maskedTaxID.length > 0 && maskedTaxID.includes("X")) {
			setTaxIDHelper(maskedTaxID);

			const p = addTaxIDDashes(taxID);

			if (maskedTaxID.endsWith("-") && !p.endsWith("-")) {
				setMaskedTaxID(p + "-");
			} else if (!maskedTaxID.endsWith("-") && p.endsWith("-")) {
				setMaskedTaxID(p.slice(0, -1));
			} else {
				setMaskedTaxID(p);
			}

			// setMaskedSSN(addDashes(SSN))
		} else {
			setMaskedTaxID(taxIDHelper);
			setTaxIDHelper(taxID);
		}
	};

	// =========================================end of Tax ID==================================================

	const handleClaimStatusSubmit = async () => {
		// const filter = {
		// 	SSN,
		// 	date_of_birth: dateOfService,
		// 	tax_id: taxID,
		// 	date_of_service: dateOfService
		// };
		// console.log("===>filter", filter);
		if (SSN === "" || dateOfBirthPatient === "" || taxID === "" || dateOfService === "") {
			setError({
				dateOfBirthPatient: dateOfBirthPatient === "",
				SSN: SSN === "",
				taxID: taxID === "",
				dateOfService: dateOfService === ""
			});
			return;
		}
		const wltDob = monthDayFormatterForWLTQuery(dateOfBirthPatient);
		const wltDateOfService = monthDayFormatterForWLTQuery(dateOfService);
		const response = await api.claimStatus.getClaimStatusDetails(SSN, wltDob, taxID, wltDateOfService);
		console.log("===========Claim Response===========", response);
		if (response) {
			const { claimResult, memberResult } = response;
			if (!memberResult) {
				alert("Sorry There is no claim found!");
			}
			setMemberDetails(memberResult);
			setClaimStatusDetails(claimResult);
		}
	};

	const handleResetButtonClick = () => {
		setSSN("");
		setMaskedSSN("");
		setTaxID("");
		setMaskedTaxID("");
		setDateOfBirthPatient("");
		setDateOfService("");
	};

	console.log("memberDetails", memberDetails);

	return (
		<div className="claim-status-main">
			<div className="theme-header-1 header">Claim Status</div>
			<ClaimStatusQueryForm
				SSNIcon={showHideIcon}
				taxIDIcon={taxIDShowHideIcon}
				calimStatusErrorDetails={error}
				maskedSSN={maskedSSN}
				maskedTaxID={maskedTaxID}
				handleSSNChange={handleSSNChange}
				handleDateOfServiceChange={handleDateOfServiceChange}
				handleDobChange={handleDobChange}
				handleViewClick={handleViewClick}
				handleTaxIDChange={handleTaxIDChange}
				handleTaxIDViewClick={handleTaxIDViewClick}
				handleClaimStatusSubmit={handleClaimStatusSubmit}
				dateOfBirthPatient={dateOfBirthPatient}
				dateOfService={dateOfService}
				handleResetButtonClick={handleResetButtonClick}
			/>
			{alertStatus === true && memberDetails && claimStatusDetails.length === 0 ? (
				<Stack sx={{ width: "20%", marginTop: 4, marginBottom: 4 }} spacing={2}>
					<Alert sx={customStyle.customSuccessAlert}>
						<strong>Member fetched Successfully!</strong> but claim not found
					</Alert>
				</Stack>
			) : alertStatus === true && memberDetails && claimStatusDetails.length > 0 ? (
				<Stack sx={{ width: "20%", marginTop: 4, marginBottom: 4 }} spacing={2}>
					<Alert sx={customStyle.customSuccessAlert}>
						<strong>Successfull!</strong> Member and Claim has been fetched
					</Alert>
				</Stack>
			) : alertStatus === false ? (
				<Stack sx={{ width: "20%", marginTop: 4, marginBottom: 4 }} spacing={2}>
					<Alert severity="error" sx={customStyle.customFailAlert}>
						<strong>Sorry!</strong> Failed to fetch Claim Status
					</Alert>
				</Stack>
			) : null}
			{memberDetails && (
				<Alert severity="warning" sx={{ fontSize: "1rem", width: "40%" }} className="disclaimer-container">
					<strong className="disclaimer">Disclaimer:</strong> The information provided here is for general
					informational purposes only. It does not constitute professional advice. Any reliance you place on
					such information is strictly at your own risk. Always seek the advice of a qualified professional
					for any concerns you may have. We do not endorse or guarantee the accuracy, completeness, or
					suitability of the information provided.
				</Alert>
			)}
			{/* {alertStatus && memberDetails && claimStatusDetails.length > 0 ? (
				<>
					<ClaimStatusMemberDetails memberDetails={memberDetails} />
					<ClaimStatusDetailsGrid memberDetails={memberDetails} claimStatusList={claimStatusDetails} />
				</>
			) : (
				<h3>No Claim Found</h3>
			)} */}

			{alertStatus && memberDetails && <ClaimStatusMemberDetails memberDetails={memberDetails} />}
			{memberDetails && (
				<Alert
					severity="warning"
					sx={{ fontSize: "1rem", width: "40%", marginTop: "30px" }}
					className="disclaimer-container"
				>
					<strong className="disclaimer">Disclaimer:</strong> This section displays only claims that have been
					fully processed and paid. Claims that are still in progress, pending approval, or under review will
					not appear here. If you have submitted a claim and it is not visible, it may still be undergoing
					processing. For updates on the status of in-progress claims or any further inquiries, please contact
					our customer care team. Our representatives are available to assist you with detailed information
					regarding your claim status and any other questions you may have.
				</Alert>
			)}
			{alertStatus && memberDetails && claimStatusDetails.length > 0 ? (
				<ClaimStatusDetailsGrid memberDetails={memberDetails} claimStatusList={claimStatusDetails} />
			) : (
				<>
					{/* <br /> */}
					{/* <h3>No Claim Found</h3> */}
				</>
			)}
		</div>
	);
};

export default ClaimStatus;
